import React, {useCallback, useMemo, useState} from 'react'
import {Plan as PlanViewer} from '@wix/seating-viewer'
import {SeatingPlan, Type} from '@wix/ambassador-seating-v1-seating-plan/build/es/types'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useViewBox} from '../../../../hooks/viewbox'
import {PopoverData, SeatingPopover} from '../seating-popover'
import {getSeatWithTicketInfo} from '../../../../selectors/seating'
import {SelectedPlace} from '../../../../types/seating'
import {Controls} from './controls'
import {Legend} from './legend'
import s from './plan.scss'
import {PlanProps} from '.'

const MIN_ZOOM = 0.2
const MAX_ZOOM = 2
const ZOOM_STEP = 0.2

const calculatePlacesStock = (plan: SeatingPlan, selectedSeats: SelectedPlace[]) => {
  const places = plan.categories.flatMap(category => category.places).filter(place => place.elementType === Type.AREA)

  return places.reduce((acc, place) => {
    const selectedPlacesCount = selectedSeats.filter(seat => seat.id === place.id).length
    return {...acc, [place.id]: place.capacity - selectedPlacesCount}
  }, {} as Record<string, number>)
}

export const Plan = ({plan, selectedSeat, selectedSeats, onSeatClick, event, tickets, t}: PlanProps) => {
  const {isMobile} = useEnvironment()
  const container = React.useRef<HTMLDivElement>(null)
  const {viewBox, zoom, cursor, changeSvgViewBox, onPointerDown, onPointerMove, onPointerUp} = useViewBox(
    plan,
    container.current,
  )
  const selectedPlaceIds = useMemo(() => selectedSeats.map(seat => seat.id), [selectedSeats])
  const [popoverData, setPopoverData] = useState(null as PopoverData)
  const placesStock = useMemo(() => calculatePlacesStock(plan, selectedSeats), [plan, selectedSeats])

  const onPlaceMouseEnter = useCallback(params => {
    const {categoryId, seat} = params
    const info = getSeatWithTicketInfo({plan, event, seat, categoryId, t, tickets})
    if (!info) {
      return null
    }
    const containerBoundingClientRect = container.current.getBoundingClientRect()
    const boundingClientRect = params.event.currentTarget.getBoundingClientRect()

    setPopoverData({boundingClientRect, info, containerBoundingClientRect} as PopoverData)
  }, [])

  const onPlaceMouseLeave = useCallback(() => {
    setPopoverData(null)
  }, [])

  return (
    <div
      ref={container}
      className={s.container}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerLeave={onPointerUp}
      onPointerMove={onPointerMove}
      style={{cursor}}
    >
      <PlanViewer
        onPlaceMouseLeave={isMobile ? null : onPlaceMouseLeave}
        onPlaceMouseEnter={isMobile ? null : onPlaceMouseEnter}
        placesStock={placesStock}
        currentlyClickedPlaceId={selectedSeat?.id}
        selectedPlaceIds={selectedPlaceIds}
        plan={plan}
        svgViewBox={viewBox}
        onPlaceClick={onSeatClick}
      />
      <Legend />
      <Controls
        zoomOutDisabled={zoom === MIN_ZOOM}
        zoomInDisabled={zoom === MAX_ZOOM}
        onZoomIn={() => changeSvgViewBox({deltaScale: ZOOM_STEP})}
        onZoomOut={() => changeSvgViewBox({deltaScale: -ZOOM_STEP})}
      />
      {popoverData ? <SeatingPopover popoverData={popoverData} /> : null}
    </div>
  )
}
