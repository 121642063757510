import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SlideInModal} from '../../slide-in-modal'
import {TicketsListContainer} from '../tickets-list-container'
import {Ticket} from '../ticket'
import {SelectedPlace} from '../../../../types/seating'
import {getSeatWithTicketInfo} from '../../../../selectors/seating'
import {Header} from './header'
import {Filters} from './filters'
import {classes} from './accessibility-mode.st.css'
import {Footer} from './footer'
import {AccessibilityModeProps} from '.'

export const AccessibilityMode = ({
  showAccessibilityMode,
  tickets,
  plan,
  event,
  selectedTickets: currentlySelectedTickets,
  selectedLocation,
  filteredCategories,
  setShowAccessibilityMode,
}: AccessibilityModeProps) => {
  const {t} = useTranslation()
  const [selectedSeats, setSelectedSeats] = React.useState<SelectedPlace[]>([])
  const currentlySelectedSeatIds = Object.values(currentlySelectedTickets).flatMap(({seatIds}) => seatIds)

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.BACK), canFocus: true})
    addCircularTabFocus(hookToAttributeSelector(DH.CLOSE_BUTTON), hookToAttributeSelector(DH.ACCESSIBILITY_MODE_END))
  }, [showAccessibilityMode])

  const seats: SelectedPlace[] = filteredCategories
    .flatMap(category =>
      category.places
        .filter(
          seat =>
            seat.capacity > 0 &&
            !currentlySelectedSeatIds.includes(seat.id) &&
            (selectedLocation ? seat.id.split('-')[1] === selectedLocation : true),
        )
        .map(seat => getSeatWithTicketInfo({plan, tickets, event, seat, categoryId: category.id, t})),
    )
    .sort((a, b) => b.capacity - a.capacity)

  return (
    <SlideInModal show={showAccessibilityMode} onBackClick={() => setShowAccessibilityMode(false)}>
      <Header />
      <Filters />
      <Text className={classes.seatsInfo}>Total Seats: {seats.length}</Text>
      <TicketsListContainer>
        {seats.map((seat, index) => {
          const selected = selectedSeats.some(selectedSeat => selectedSeat.id === seat.id)

          return (
            <Ticket
              key={seat.id}
              lastTicket={index === seats.length - 1}
              place={seat}
              onClick={clickedSeat =>
                selected
                  ? setSelectedSeats(prevSelectedSeats =>
                      prevSelectedSeats.filter(selectedSeat => selectedSeat.id !== seat.id),
                    )
                  : setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, clickedSeat])
              }
              withCheckbox
              selected={selected}
            />
          )
        })}
      </TicketsListContainer>
      <Footer selectedSeats={selectedSeats} />
    </SlideInModal>
  )
}
